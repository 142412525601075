import { z } from 'zod';
import { useEffect, useState } from 'react';
import { useAuth } from '@/providers/auth.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp.tsx';
import { Button } from '@/components/ui/button.tsx';
import { BiCheck } from 'react-icons/bi';

const SuccessMessage = () => {
  return (
    <div className="flex gap-x-2 items-center">
      <BiCheck color="white" className="bg-success rounded-full" />
      <p className="text-success font-medium">
        New code on its way to your inbox!
      </p>
    </div>
  );
};

const OTPFormSchema = z.object({
  pin: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.',
  }),
});

const OTPCodeForm = ({
  email,
  handleRequestCode,
  loading,
}: {
  email: string;
  handleRequestCode: (email: string) => void;
  loading: boolean;
}) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [resendSuccess, setResendSuccess] = useState<boolean>(false);
  // Not currently doing anything with the error state, but keeping it anyway
  const [_resendError, setResendError] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showTimerMessage, setShowTimerMessage] = useState(false);

  const { loginOTP } = useAuth();
  const form = useForm<z.infer<typeof OTPFormSchema>>({
    resolver: zodResolver(OTPFormSchema),
    defaultValues: {
      pin: '',
    },
    mode: 'onBlur',
  });

  const startTimer = () => {
    setTimeLeft(30);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }

    if (timeLeft === 0) {
      setShowTimerMessage(false);
    }

    return () => clearTimeout(timer); // Cleanup on unmount or timeLeft change
  }, [timeLeft]);

  useEffect(() => {
    setTimeout(() => {
      if (resendSuccess) {
        setResendSuccess(false);
      }
    }, 5000);
  }, [resendSuccess]);

  const onSubmit = async (credentials: z.infer<typeof OTPFormSchema>) => {
    const { pin } = credentials;
    setSubmitLoading(true);
    try {
      await loginOTP(email, pin);
      setSubmitLoading(false);
    } catch (error) {
      const errorResponse = (error as any).response.data.error;
      let readableErrorMessage = '';

      switch (errorResponse) {
        case 'Expired passcode':
          readableErrorMessage = 'The code has expired.';
          break;
        case 'Invalid passcode':
          readableErrorMessage = 'Code is incorrect, please try again.';
          break;
        default:
          readableErrorMessage = 'An unexpected error occurred.';
          break;
      }
      form.setError('pin', {
        type: 'server',
        message: readableErrorMessage,
      });
      setSubmitLoading(false);
    }
  };

  const handleResend = async () => {
    if (timeLeft > 0) {
      setShowTimerMessage(true);
      return;
    }
    try {
      await handleRequestCode(email);
      startTimer();
      setResendSuccess(true);
    } catch (error) {
      setResendError(true);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-y-4 px-4 lg:px-0"
      >
        <h2 className="font-semibold text-2xl">Security Code</h2>
        <p className="text-muted-foreground font-medium">
          Enter the one-time password sent to{' '}
          <span className="text-foreground">{email}</span>
        </p>
        <FormField
          control={form.control}
          name="pin"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputOTP data-testid="pin" maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {!resendSuccess && !showTimerMessage ? (
          <Button
            data-testid="resend-otp"
            type="button"
            onClick={handleResend}
            variant="link"
            className="text-primary w-fit underline p-0"
            loading={loading}
          >
            Send new code
          </Button>
        ) : resendSuccess && !showTimerMessage ? (
          <SuccessMessage />
        ) : (
          <p className="text-muted-foreground font-medium">
            Please wait {timeLeft} seconds before requesting a new code.
          </p>
        )}

        <div className="flex justify-between items-center">
          <Button loading={submitLoading} className="w-full" type="submit">
            Login
          </Button>
        </div>
        <p className="text-muted-foreground font-medium">
          Need help? Contact us at care@peopled.com.
        </p>
      </form>
    </Form>
  );
};

export default OTPCodeForm;
