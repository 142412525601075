import { useFinchStore } from '@/routes/dashboard/employees/finch/store.ts';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router';
import FinchDetails from '@/routes/dashboard/employees/finch/finch-details.tsx';
import FinchConnected from '@/routes/dashboard/employees/finch/finch-connected.tsx';
import FinchReauthenticate from '@/routes/dashboard/employees/finch/finch-reauthenticate.tsx';
import { Card } from '@/components/ui/card.tsx';
import { Skeleton } from '@/components/ui/skeleton.tsx';

const Finch = () => {
  const [searchParams] = useSearchParams();
  const {
    checkFinchConnected,
    checkFinchEnabled,
    checkFinchNeedsReauth,
    connection,
    employees,
    handleAccessCode,
    init,
    loading,
    login,
  } = useFinchStore();

  const finchCode = searchParams.get('code');

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (finchCode && !checkFinchConnected()) {
      handleAccessCode(finchCode);
    }
  }, [finchCode]);

  // Show loading skeleton if loading or if we just received a code but connection isn't ready yet
  if (loading || (finchCode && !checkFinchConnected())) {
    return (
      <Card>
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-y-3">
            <Skeleton className="h-6 w-[250px]" />
            <Skeleton className="h-3 w-[250px]" />
          </div>
        </div>
      </Card>
    );
  }
  return (
    <>
      {checkFinchNeedsReauth() ? (
        <div className="mb-4">
          <FinchReauthenticate connection={connection} url={login!.login_url} />
        </div>
      ) : null}

      {checkFinchEnabled() &&
      !checkFinchNeedsReauth() &&
      !loading &&
      !finchCode ? (
        <div className="mb-4">
          <FinchDetails
            connection={connection}
            loginStatus={login!}
            url={login!.login_url}
          />
        </div>
      ) : null}

      {checkFinchConnected() ? (
        <div className="mb-4">
          <FinchConnected
            connection={connection!}
            employeeCount={employees.length}
            finchEmployees={employees}
            lastSyncDate={connection?.data_refreshed_at}
          />
        </div>
      ) : null}
    </>
  );
};

export default Finch;
