import { Button } from '@/components/ui/button.tsx';
import { BiDislike, BiLike, BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { useState } from 'react';

const ChatRating = () => {
  type Rating = 'positive' | 'negative';
  const [rating, setRating] = useState<Rating | null>(null);

  // @TODO Implement rating logic from the backend to be applied here
  const handleClickRating = (newRating: Rating) => {
    if (rating === newRating) {
      setRating(null);
    } else {
      setRating(newRating);
    }
  };

  return (
    <div className="flex">
      <Button
        className="p-2 rounded-full hover:bg-input"
        variant="ghost"
        size="empty"
        onClick={() => handleClickRating('positive')}
      >
        {rating === 'positive' ? (
          <BiSolidLike size={15} />
        ) : (
          <BiLike size={15} />
        )}
      </Button>
      <Button
        className="p-2 rounded-full hover:bg-input"
        variant="ghost"
        size="empty"
        onClick={() => handleClickRating('negative')}
      >
        {rating === 'negative' ? (
          <BiSolidDislike size={15} />
        ) : (
          <BiDislike size={15} />
        )}
      </Button>
    </div>
  );
};

export default ChatRating;
