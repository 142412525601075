import { Separator } from '@/components/ui/separator.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Card } from '@/components/ui/card.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useState } from 'react';
import OrganizationForm from '@/routes/dashboard/organization/organization-form.tsx';

export default function OrganizationDetailsCard({
  organization,
  onUpdate,
}: {
  organization: any;
  onUpdate: () => void;
}) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
        <DialogContent
          data-testid="org-edit-modal"
          className="overflow-y-scroll max-h-screen"
        >
          <DialogTitle className="hidden">Edit Organization Form</DialogTitle>
          <DialogDescription className="hidden">
            Editing organization
          </DialogDescription>
          <OrganizationForm organization={organization} onSuccess={onUpdate} />
        </DialogContent>
      </Dialog>
      <Card className="flex-1">
        {/* @TODO convert <p> tags to <dl> lists for accessibility and semantics*/}
        <h3 className="font-bold text-xl">Org Information</h3>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <p className="flex-1">Entity Name</p>
            <p className="flex-1" data-testid="org-name" test-id="org-name">
              {organization.name}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">EIN</p>
            <p
              className="flex-1"
              data-testid="org-business-tax-id"
              test-id="org-business-tax-id"
            >
              {organization.business_tax_id}
            </p>
          </div>
        </div>
        <h3 className="font-bold text-xl my-4">Org Address</h3>
        <div className="flex-col gap-2">
          <div className="flex gap-2">
            <p className="flex-1">Address 1</p>
            <p
              className="flex-1"
              data-testid="org-business-address"
              test-id="org-business-address"
            >
              {organization.business_address}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Address 2</p>
            <p
              className="flex-1"
              data-testid="org-business-address-line-two"
              test-id="org-business-address-line-two"
            >
              {organization.business_address_line_two}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">City</p>
            <p
              className="flex-1"
              data-testid="org-business-address-city"
              test-id="org-business-address-city"
            >
              {organization.business_address_city}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">State</p>
            <p
              className="flex-1"
              data-testid="org-business-address-state"
              test-id="org-business-address-state"
            >
              {organization.business_address_state}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Zip Code</p>
            <p
              className="flex-1"
              data-testid="org-business-address-postal-code"
              test-id="org-business-address-postal-code"
            >
              {organization.business_address_postal_code}
            </p>
          </div>
        </div>
        <h3 className="font-bold text-xl my-4">Contact Information</h3>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <p className="flex-1">Primary Contact Name</p>
            <p
              className="flex-1"
              data-testid="org-primary-contact-full-name"
              test-id="org-primary-contact-full-name"
            >
              {organization.primary_contact_full_name}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Primary Contact Phone Number</p>
            <p
              className="flex-1"
              data-testid="org-primary-contact-phone-number"
              test-id="org-primary-contact-phone-number"
            >
              {organization.primary_contact_phone_number}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="flex-1">Primary Contact Email</p>
            <p
              className="flex-1"
              data-testid="org-primary-contact-email"
              test-id="org-primary-contact-email"
            >
              {organization.primary_contact_email}
            </p>
          </div>
        </div>
        <Separator className="my-4" />
        <div className="flex justify-end">
          <Button
            data-testid="org-edit-button"
            test-id="org-edit-button"
            onClick={() => setEditModalOpen(!editModalOpen)}
          >
            Edit
          </Button>
        </div>
      </Card>
    </>
  );
}
