import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { statesList } from '@/lib/utils.ts';
import { ReactNode, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { Button } from '@/components/ui/button.tsx';
import { IOrganization } from '@/types.ts';
import { TextField } from '@/components/text-field.tsx';
import { Separator } from '@/components/ui/separator.tsx';

const formSchema = z.object({
  name: z.string().min(2).max(50),
  business_address: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_line_two: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  business_address_city: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_state: z.string().optional().or(z.literal('')),
  business_address_postal_code: z
    .string()
    .regex(/^\d{5}$/)
    .optional()
    .or(z.literal('')),
  business_tax_id: z
    .string()
    .regex(/^\d{2}-\d{7}$/)
    .optional()
    .or(z.literal('')),
  primary_contact_full_name: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  primary_contact_email: z.string().email().optional().or(z.literal('')),
  primary_contact_phone_number: z
    .string()
    .regex(/^\d{3}-\d{3}-\d{4}$/)
    .optional()
    .or(z.literal('')),
});

const formDefaults = {
  name: '',
  business_address: '',
  business_address_line_two: '',
  business_address_city: '',
  business_address_state: '',
  business_address_postal_code: '',
  business_tax_id: '',
  primary_contact_full_name: '',
  primary_contact_email: '',
  primary_contact_phone_number: '',
};
export default function OrganizationForm({
  onSuccess,
  organization,
}: {
  organization?: IOrganization;
  onSuccess: () => void;
}) {
  const [statusMessage, setStatusMessage] = useState<ReactNode>('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: organization
      ? {
          ...formDefaults,
          ...Object.fromEntries(
            // replacing null values with empty strings
            Object.entries(organization).map(([key, value]) => [
              key,
              value === null ? '' : value,
            ])
          ),
        }
      : formDefaults,
  });

  // Using an effect here as a watcher to manage the button / message states
  useEffect(() => {
    if (loading) {
      setStatusMessage('');
      return;
    }
    if (success) {
      return;
    }
    if (error) {
      setStatusMessage('Error creating organization.');
      return;
    }

    setStatusMessage('');
  }, [loading, success, error]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    sdk
      .updateOrganization(organization!.id, {
        ...data,
      })
      .then(() => {
        setSuccess(true);
        setStatusMessage('Organization updated successfully');
        onSuccess();
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
        setLoading(false);
      });
  };
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <h1 className="mb-4 text-xl font-semibold">Company Information</h1>
          <Separator />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    required
                    label="Company Name"
                    data-testid="org-name-input"
                    test-id="org-name-input"
                    error={form.formState.errors.name?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_tax_id"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="EIN"
                    data-testid="org-business-tax-id-input"
                    test-id="org-business-tax-id-input"
                    helperText="ex: 12-3456789"
                    error={form.formState.errors.business_tax_id?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Separator />
          <h1 className="my-2 text-xl font-semibold">Company Address</h1>
          <FormField
            control={form.control}
            name="business_address"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Address 1"
                    data-testid="org-business-address-input"
                    test-id="org-business-address-input"
                    error={form.formState.errors.business_address?.message}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_line_two"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Address 2"
                    error={
                      form.formState.errors.business_address_line_two?.message
                    }
                    data-testid="org-business-address-line-two-input"
                    test-id="org-business-address-line-two-input"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_city"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="City"
                    data-testid="org-business-address-city-input"
                    test-id="org-business-address-city-input"
                    error={form.formState.errors.business_address_city?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div className="mb-2">
            <FormField
              control={form.control}
              name="business_address_state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>State</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    {/* The difference in padding in firefox and chrome is insane */}
                    {/* pl-4 is the closest hack I can get both of them to look ok */}
                    <FormControl>
                      <SelectTrigger
                        data-testid="state-dropdown"
                        test-id="state-dropdown"
                      >
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <FormMessage />
                    <SelectContent>
                      {statesList.map((state) => (
                        <SelectItem
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="business_address_postal_code"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Postal Code"
                    data-testid="org-business-address-postal-code-input"
                    test-id="org-business-address-postal-code-input"
                    helperText="ex: 00000"
                    error={
                      form.formState.errors.business_address_postal_code
                        ?.message
                    }
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Separator />
          <h1 className="my-4 text-xl font-bold">
            Primary Contact Information
          </h1>
          <FormField
            control={form.control}
            name="primary_contact_full_name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Primary Contact Name"
                    data-testid="org-primary-contact-full-name-input"
                    test-id="org-primary-contact-full-name-input"
                    error={
                      form.formState.errors.primary_contact_full_name?.message
                    }
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="primary_contact_phone_number"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Phone Number"
                    data-testid="org-primary-contact-phone-number-input"
                    test-id="org-primary-contact-phone-number-input"
                    helperText="123-456-7890"
                    error={
                      form.formState.errors.primary_contact_phone_number
                        ?.message
                    }
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="primary_contact_email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextField
                    label="Email Address"
                    data-testid="org-primary-contact-email-input"
                    test-id="org-primary-contact-email-input"
                    error={form.formState.errors.primary_contact_email?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Separator />
          <div className="flex justify-between items-center">
            <div>{statusMessage}</div>
            <Button
              data-testid="org-edit-submit"
              test-id="org-edit-submit"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
