interface ChatDisclaimerProps {
  copy?: string;
}

const ChatDisclaimer = ({ copy }: ChatDisclaimerProps) => {
  const defaultDisclaimer =
    'We strive for accuracy, but your situation may vary. For tailored guidance, reach out to your HR team.';
  return (
    <div className="text-sm w-full flex flex-col items-center mb-2">
      <div className="max-w-[640px] w-full flex gap-x-2.5 justify-center mt-4 mb-2">
        <a
          href="https://www.peopled.com/terms-of-service-1"
          target="_blank"
          className="text-link underline"
        >
          Terms of Service
        </a>
        <span>|</span>
        <a
          href="https://www.peopled.com/privacy-policy-1"
          target="_blank"
          className="text-link underline"
        >
          Privacy Policy
        </a>
      </div>
      <p className="text-muted-foreground">{copy || defaultDisclaimer}</p>
    </div>
  );
};

export default ChatDisclaimer;
