import { TextField } from '@/components/text-field.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import {
  EmployeeStatus,
  useEmployeesStore,
} from '@/routes/dashboard/employees/store.ts';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { Button } from '@/components/ui/button.tsx';
import { BiChevronDown, BiRefresh, BiUpload, BiUserPlus } from 'react-icons/bi';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router';

interface EmployeesToolbarProps {
  finchEnabled: boolean;
  finchConnected: boolean;
  handleSearchChange: (e: SyntheticEvent) => void;
  showModalCallback: () => void;
}

const EmployeesToolbar = ({
  finchEnabled,
  finchConnected,
  handleSearchChange,
  showModalCallback,
}: EmployeesToolbarProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center mt-4">
      <div className="flex flex-auto items-center gap-x-2">
        <TextField
          data-testid="employee-search"
          className="max-w-[205px] text-sm"
          placeholder="Search by name or email"
          onChange={handleSearchChange}
        />

        <div className="max-w-[200px] w-full">
          <Select
            onValueChange={(value) => {
              const v = value === 'none' ? '' : value;
              useEmployeesStore.setState({
                statusFilter: v as EmployeeStatus,
              });
            }}
          >
            <SelectTrigger data-testid="employee-status-filter">
              <SelectValue placeholder="Filter by Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Filter by Status</SelectLabel>
                <SelectItem
                  data-testid="employee-status-filter-all"
                  value="none"
                >
                  All Statuses
                </SelectItem>
                <SelectItem
                  data-testid="employee-status-filter-registered"
                  value="registered"
                >
                  Registered
                </SelectItem>
                <SelectItem
                  data-testid="employee-status-filter-pre-invitation"
                  value="pre-invitation"
                >
                  Pending Invitation
                </SelectItem>
                <SelectItem
                  data-testid="employee-status-filter-re-invited"
                  value="re-invited"
                >
                  Re-Invited
                </SelectItem>
                <SelectItem
                  data-testid="employee-status-filter-invited"
                  value="invited"
                >
                  Invited
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex flex-1 justify-end">
        <DropdownMenu>
          {!finchConnected ? (
            <DropdownMenuTrigger asChild>
              <Button>
                Add Employee
                <BiChevronDown className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
          ) : null}
          <DropdownMenuContent>
            {finchEnabled ? (
              <DropdownMenuItem
                test-id="add-employee-finch-button"
                onClick={() => ''}
              >
                <BiRefresh />
                Import from Payroll
              </DropdownMenuItem>
            ) : null}
            <DropdownMenuItem
              test-id="add-employee-button"
              onClick={showModalCallback}
            >
              <BiUserPlus />
              Add Manually
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate('/dashboard/census')}>
              <BiUpload />
              Bulk upload
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default EmployeesToolbar;
